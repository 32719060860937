/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 14:58:21
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-12 15:18:21
 */

// import { parseTime } from '@/plugins/lib/modules/date'
// import { remit, getType } from '@/utils/options'
import { formStatus, getType } from '@/utils/options';

export var columns = function columns(ctx) {return [
  {
    label: '序号',
    type: 'index',
    width: 100 },

  {
    label: '标题',
    kname: 'title' },

  {
    label: '文章分类',
    kname: 'typeName' },

  {
    label: '时间',
    kname: 'createAt' },

  {
    label: '状态',
    kname: 'status',
    component: {
      name: 'c-switch',
      events: {
        change: ctx.changeStatus },

      props: {} } },



  {
    label: '操作',
    kname: 'btns',
    width: 160 }];};




export var searchOption = [
{
  kname: 'title',
  label: '搜索：',
  component: {
    name: 'el-input' } },


{
  kname: 'typeId',
  label: '分类：',
  component: {
    name: 'selector',
    props: {
      type: 'articleType',
      clearable: true } } },



{
  kname: 'startTime',
  label: '' },

{
  kname: 'endTime',
  label: '' }];